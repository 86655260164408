<template>
	<main>
		<div id="primary" class="p-t-b-100 height-full ">
			<div class="container">
				<div class="row">
					<div class="col-lg-4 mx-md-auto">
						<div class="text-center">
							<figure class="avatar avatar-xl">
								<img src="../assets/img/logo-255x255.png" alt="">
							</figure>

							<h3 class="mt-2">管理员登录</h3>
							<p class="p-t-b-20 text-danger s-24">{{err}}</p>
						</div>
						<form action="dashboard2.html">
							<div class="form-group has-icon focused"><i class="icon-home2"></i>

								<input v-model="name" type="text" class="form-control form-control-lg"
									placeholder="企业名称" readonly>
								<i class="icon-search" style="margin-left: 318px;" @click="searchReady"></i>
							</div>

							<div class="form-group has-icon focused"><i class="icon-user-o"></i>
								<input v-model="userName" type="text" class="form-control form-control-lg"
									placeholder="帐号">
							</div>
							<div class="form-group has-icon focused"><i class="icon-key5"></i>
								<input v-model="password" type="password" class="form-control form-control-lg"
									placeholder="密码" autocomplete="new-password">
							</div>
							<button @click="login" type="button" class="btn btn-success btn-lg btn-block">管理员登录</button>
							<!-- <p class="forget-pass">Have you forgot your username or password ?</p> -->
						</form>
					</div>
				</div>
			</div>
		</div>
		<!-- #primary -->


		<div class="modal" id="modal" tabindex="-1">
			<div class="modal-dialog">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title">查询企业</h5>
						<button type="button" class="close" data-dismiss="modal" aria-label="Close">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<div class="form-group">
							<input v-model="type" value="0" type="radio" name="gender" id="male" class="with-gap">
							<label for="male">服务商(A)</label>

							<input v-model="type" value="1" type="radio" name="gender" id="female" class="with-gap ml-5">
							<label for="female" class="m-l-20">企业(B)</label>
						</div>
						<div class="form-group focused">
							<!-- <label for="">查询商户号</label> -->
							<div class="input-group">
								<input type="text" v-model="query" class="form-control r-0" id=""
									placeholder="搜索关键字...">
								<div class="input-group-append">
									<button type="button" class="btn btn-primary" @click="doSearch">查询</button>
								</div>
							</div>
						</div>

						<div class="card" v-if="outletlist.length>0">
							<div class="card-header white">
								<strong>查询结果({{outletlist.length}})</strong>
							</div>
							<div class="card-body p-0">
								<ul class="list-group no-b">
									<li v-for="(item, index) in outletlist" v-bind:key="index"
										class="list-group-item d-flex justify-content-between align-items-center">
										<div>
											{{index+1}}.{{item.name}} {{item.outlet_code}}
										</div>
										<div class="material-switch" @click="onChangeRadioGroup(index)">
											<input name="someSwitchOption001" type="checkbox"
												:checked="selectedIndex == index">
											<label for="" class="bg-primary"></label>
										</div>
									</li>
								</ul>
							</div>
						</div>

					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-secondary" data-dismiss="modal">取消</button>
						<!-- <button type="button" class="btn btn-primary" @click="doSearch">确定</button> -->
					</div>
				</div>
			</div>
		</div>
		<!-- modal -->
	</main>
</template>

<script>
	export default {
		data() {
			return {
				err: '',
				
				outlet_code: '',
				userName: '',
				password: '',
				
				query: '',
				outletlist: [],
				selectedIndex: -1,
				
				////
				type: 0,//企业类型：0服务，1企业
				name: '',
			}
		},
		
		created: function() {
			let self = this;
		},
		mounted: function() {
		},
		methods: {
			login(){
				let self = this;
				console.log(self.userid,self.password);
				// return;
				
				if(!self.outlet_code){
					self.err = '企业不能为空';
					return;
				}
				
				if(!self.userName){
					self.err = '帐号不能为空';
					return;
				}
				
				if(!self.password){
					self.err = '密码不能为空';
					return;
				}
				
				self.err = ''; 
				
				self.axios.get(self.GLOBAL.baseURI + 'loginForSupperUser', {
					params: {
						outlet_code: self.outlet_code,
						userid: self.userName,
						password: self.password,
						type: self.type,//登录类型：0登录A企业，1登录B企业
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data.data);
					if (data.code == 200) {
					   
					   if(data.data.result_code == 'success'){
						   console.log('--- token', localStorage, data.data.access_token);
						   
						   // localStorage.setItem("SETTING", JSON.stringify(data.data.setting));
						   
						   let loginData = data.data;
						   // delete loginData['setting']
						   localStorage.setItem("LOGINDATA", JSON.stringify(loginData));
						   
						   localStorage.setItem("TOKEN", data.data.access_token);
						   
						   // self.getDeptList();
						   self.$store.commit('setLoginStatus', true);
						   self.$router.replace({name: 'dashboard'});
					   }
					   else{
						   self.err = data.data.result_msg;
					   }
						
					}
					 else if (data.code == '403') {
					     // alert(data.message)
					     self.err = '没有权限';
					     // alert('没有权限')
					 }
					 else if (data.code == '3001') {
					    // alert(data.message)
					    self.err = '帐号不存在';
					    // alert('帐号不存在')
					} else if (data.code == '3002') {
					    // alert(data.message)
					    self.err = '密码错误';
					    // alert('密码错误')
					} else if (data.code == '3008') {
					    // alert(data.message)
					    self.err = '没有权限';
					    // alert('没有权限')
					} else if (data.code == '9999') {
					    // alert(data.message)
					    self.err = '商户类型错误';
					    // alert('商户类型错误')
					} else if (data.code == '1507') {
					    // alert(data.message)
					    self.err = '授权到期';
					    // alert('授权到期')
					} else {
					    // alert(data.message)
					    self.err = data.message;
					    // alert('帐号异常')
					}
					
				});
			},
			
			getOutletList(){
				var self = this;
				
				self.axios.get(self.GLOBAL.baseURI + 'getOutletList', {
					params: {
						token: localStorage.getItem("TOKEN"),
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data);
					if (data.code == 200) { 
						localStorage.setItem("OUTLETLIST", JSON.stringify(data.data.outlets));
						self.$store.commit('setLoginStatus', true);
						// console.log('--- isLogin @login.vue ->',self.$store.state.isLogin)
						
						// location.href = 'app.html';
						// self.$router.push({path: '/admin/account'});
						self.$router.replace({name: 'dashboard'});
					}
				});
				
			},
			
			searchReady(){
				console.log('--- searchReady ---')
				
				this.selectedIndex = -1;
				
				$('#modal').modal('show');
				
			},
			
			doSearch(){
				console.log('--- doSearch ---')
				
				var self = this;
				
				if(!self.query) return;
				
				let url = self.type==1?'searchDept':'searchEnt';
				
				self.axios.get(self.GLOBAL.baseURI + url, {
					params: {
						query: self.query,
						type: self.type,
					}
				})
				.then(function(response) {
					console.log(response);
					return response.data;
				})
				.catch(function(error) {
					console.log(error);
				})
				.then(function(data) {
					// always executed
					console.log(data);
					if (data.code == 200) { 
						self.outletlist = data.data.outlets;
					}
				});
				
			},
			
			onChangeRadioGroup(index){
				console.log('--- onChangeRadioGroup ---')
				var self = this;
				
				self.selectedIndex = index;
				self.outlet_code = self.outletlist[index].outlet_code;
				self.name = self.outletlist[index].name;
				
				$('#modal').modal('hide');
			},
			
			
		},
	}
</script>

<style>
</style>
